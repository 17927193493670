import { Box, Text, VisuallyHidden } from "@biblioteksentralen/react";
import { dateString } from "@libry-content/common";
import { localizedFormat } from "../../utils/date";
import { useTranslation } from "../../utils/hooks/useTranslation";

const EventImageDateBadge = ({ date }: { date?: string }) => {
  const { lang } = useTranslation();

  return (
    <>
      <VisuallyHidden>{dateString(date, lang)}</VisuallyHidden>
      <Box
        position="absolute"
        top="0.5rem"
        left="0.5rem"
        padding="0.3rem 0.5rem"
        backgroundColor="white"
        borderRadius="0.25rem"
        width="min-content"
        lineHeight="1"
        textAlign="center"
        textTransform="uppercase"
        fontSize=".65rem"
        boxShadow="md"
        aria-hidden={true}
        color="gray.800"
      >
        <Text fontSize="1.5em" fontWeight="600">
          {localizedFormat(date, "d", lang)}
        </Text>
        <Text>{localizedFormat(date, "LLL", lang)}</Text>
      </Box>
    </>
  );
};

export default EventImageDateBadge;
