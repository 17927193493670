import { Avatar, AvatarProps, Box, ChakraProps } from "@biblioteksentralen/react";
import { User } from "react-feather";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useSitePalette } from "../../utils/useSitePalette";
import SanityImage from "../images/SanityImage";
import { PaletteColor } from "@libry-content/pdf";
import { ResolvedEmployeeSummary } from "@libry-content/common";
import { ResolvedLibrarySummary } from "../library/sanityQuery";

type Props = {
  employeeOrLibrary: ResolvedEmployeeSummary | ResolvedLibrarySummary | undefined;
  size: ChakraProps["width"];
  resolution?: number;
  fontSize?: AvatarProps["size"];
  hideCaption?: boolean;
  paletteColor?: PaletteColor;
};

const ContactAvatar = ({
  employeeOrLibrary,
  resolution,
  size,
  fontSize = "md",
  hideCaption = false,
  paletteColor,
}: Props) => {
  const { t, ts } = useTranslation();
  const sitePaletteColor = useSitePalette().colors.darkaccent4;
  const isEmployee = employeeOrLibrary?._type === "employee";

  if (!employeeOrLibrary?.image?.asset || !resolution) {
    return (
      <Avatar
        name={isEmployee ? employeeOrLibrary?.name : ts(employeeOrLibrary?.name)}
        {...sitePaletteColor.css}
        {...paletteColor}
        fontWeight="600"
        width={size}
        height={size}
        size={fontSize}
        icon={<User width="1.25rem" height="1.25rem" />}
      />
    );
  }

  return (
    <Box width={size} height={size}>
      <SanityImage
        image={employeeOrLibrary?.image}
        customNextImageProps={{
          alt: isEmployee
            ? t("Profilbilde av {ansattNavn}", { ansattNavn: employeeOrLibrary.name })
            : t("Bilde av {navn}", { navn: employeeOrLibrary?.name }),
        }}
        aspectRatio={1 / 1}
        resolution={resolution}
        borderRadius="full"
        hideCaption={hideCaption}
      />
    </Box>
  );
};

export default ContactAvatar;
