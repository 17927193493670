import { eventFilters, resolveEventSummaryGroqProjection, resolveRepeatedEvents } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { groq } from "next-sanity";
import { ContentSearcher } from "./searchContent";

// Gir mulighet til å gi treff ved søk på nøkkelord som ikke finnes i dataen
const customMatchStrings: Record<FrontendLocale, string> = {
  nb: "arrangementer aktiviteter skjer",
  nn: "arrangementer aktiviteter skjer",
};

export const searchEvents: ContentSearcher = {
  // We need to handle some advanced cases like repeated events and past events and make events apear in order if they have the same _score
  filter: (locale) => groq`
    *[ _type == "event" && ${eventFilters.todayOrLater} ] |
      score(
        boost(title.${locale} match $searchQuery, 10),
        boost(_type == "event" && "${
          customMatchStrings[locale]
        }" match $searchQuery, 5), // _type == 'event' is a hack to make content lake give an appropriate score (without it we always get a score of 1)
        boost(teaser.${locale} match $searchQuery, 5),
        boost(pt::text(body.${locale}) match $searchQuery, 1),
      )
      [_score > 0]
      ${resolveRepeatedEvents(eventFilters.repeatedEventOccurrenceNotFinished)}
      | order(eventSchedule.startsAt)
  `,
  resolver: groq`_type in ["event", "resolvedRepeatedEventOccurence"] => ${resolveEventSummaryGroqProjection}`,
};
