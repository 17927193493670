import { Box, ChakraProps, List, SimpleGrid } from "@biblioteksentralen/react";
import { useState } from "react";
import { VisuallyHiddenResults } from "../../../../components/VisuallyHiddenResults";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { RenderContentPreview } from "../RenderContentPreview";
import { SeeMoreResultsButton } from "../SeeMoreResultsButton";
import { ContentSearchItem } from "./searchers/searchContent";

type Props = {
  items: ContentSearchItem[];
  endOfResults: boolean;
  isValidating: boolean;
  paginate: () => void;
} & ChakraProps;

const ContentSearchResults = ({ items, endOfResults, isValidating, paginate, ...chakraProps }: Props) => {
  const { t } = useTranslation();

  const [autoFocusIndex, setAutoFocusIndex] = useState<number | undefined>(undefined);

  const paginateAndFocus = () => {
    //sets focus on first item of next pagination
    setAutoFocusIndex(items.length);
    paginate();
  };

  if (items.length === 0) return null;

  return (
    <Box as="section" {...chakraProps}>
      <VisuallyHiddenResults
        itemsLength={items.length}
        name={items.length === 1 ? t("side fra biblioteket") : t("sider fra biblioteket")}
      />
      <SimpleGrid as={List} width="100%" spacing=".5rem">
        {items.map((item, index) => (
          <RenderContentPreview key={item._id} item={item} autoFocus={index == autoFocusIndex} />
        ))}
      </SimpleGrid>
      {!endOfResults && <SeeMoreResultsButton marginTop="2rem" loading={isValidating} onClick={paginateAndFocus} />}
    </Box>
  );
};

export default ContentSearchResults;
