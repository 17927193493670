import { ChakraProps } from "@biblioteksentralen/react";
import { colors } from "@biblioteksentralen/utils";
import { ResolvedRecommendation, ResolvedRecommendationSummary } from "@libry-content/common";
import { Documents } from "@libry-content/types";
import { isBright } from "../../utils/colors";
import { useSitePalette } from "../../utils/useSitePalette";

export const sortRecommendationBookListByDate = <T extends Pick<Documents, "_createdAt">>(mergedArray: T[]): T[] => {
  return mergedArray.sort((a, b) => new Date(b._createdAt).valueOf() - new Date(a._createdAt).valueOf());
};

export const useRecommendationPalette = (recommendation?: ResolvedRecommendationSummary | ResolvedRecommendation) => {
  const paletteColor = useSitePalette();
  const palette = recommendation?.palette;
  const mainPalette = createPaletteColor(palette?.background ?? paletteColor.colors.card.css.background);
  const createdByPalette = createPaletteColor(palette?.createdBy);

  const teaserTextColor = mainPalette?.color;
  const teaserTextIsBright = teaserTextColor && isBright(teaserTextColor);

  const teaserTextProps = {
    color: teaserTextColor,
    textShadow: `0 0 .3em ${teaserTextIsBright ? "rgba(0,0,0,.3)" : "rgba(255,255,255,.4)"} `,
  } satisfies ChakraProps;

  const quoteMarkProps = {
    color: teaserTextIsBright ? "rgba(0,0,0,.4)" : "rgba(255,255,255,.5)",
  } satisfies ChakraProps;

  return { teaserTextProps, quoteMarkProps, mainPalette, createdByPalette };
};

const createPaletteColor = (color?: string) => ({
  backgroundColor: color,
  color: color && isBright(color) ? colors.black : colors.white,
});
