import { Box, BoxProps, Grid, ResponsiveValue } from "@biblioteksentralen/react";
import { isPublicationWithImage } from "@libry-content/common";
import { Publication } from "@libry-content/types";
import { FavoriteButtonWrapper } from "../../searchAndCollection/components/favorites/FavoriteButton";
import { getPlaceholderIcon } from "../../searchAndCollection/components/WorkCoverImage";
import { useSitePalette } from "../../utils/useSitePalette";
import { CoverImage } from "./CoverImage";

interface Props {
  publication: Publication;
  resolution?: number;
  imageWidth?: ResponsiveValue<string>;
  showFavoriteButton?: boolean;
}

const CoverImageWithPlaceholder = ({
  publication,
  resolution,
  imageWidth,
  showFavoriteButton,
  ...boxProps
}: Props & BoxProps) => {
  const palette = useSitePalette();
  const Icon = getPlaceholderIcon(publication);

  return (
    <Box
      maxWidth={imageWidth}
      borderRadius=".5rem"
      overflow="hidden"
      transition=".9s"
      height="fit-content"
      _groupHover={{ transition: ".5s", transform: "scale(1.05)" }}
      position="relative"
      {...boxProps}
    >
      {isPublicationWithImage(publication) ? (
        <CoverImage publication={publication} resolution={resolution ?? 250} />
      ) : (
        <Grid css="aspect-ratio: .7;" aria-hidden placeItems="center" {...palette.colors.darkaccent4.css}>
          <Icon width="40%" height="auto" color="white" />
        </Grid>
      )}
      {publication.workId && showFavoriteButton && (
        <FavoriteButtonWrapper position="absolute" bottom=".75rem" left=".75rem" workOrPublication={publication} />
      )}
    </Box>
  );
};

export default CoverImageWithPlaceholder;
